import { Box, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Select, Switch } from "@mui/material"
import { useTranslation } from "react-i18next"
import useStore, { UpdateStore } from "../../Store"
import { SupplierSelectorComponent } from "./SupplierSelector"
import { chargerSupplierNameToSupplier, getSuppliers } from "../charger/SupplierHelper"
import { BatteryConfiguration } from "./BatteryConfiguration"
import { PreferredChangers } from "./PreferredChangers"
import {
   PreferenceType,
   RoadProperty,
   Route,
   RoutePreference,
   RoutingAlgorithm,
} from "../../../out/gen/openapi/routeplanner"
import { RouteToNextWaypoint } from "../itinerary/RouteInfo"
import { toGeoJSON } from "@mapbox/polyline"

export const VehicleInput = () => {
   const { t } = useTranslation()
   const vehicle = useStore(store => store.vehicle)
   const routeCalculationSetting = useStore(state => state.routeCalculationSetting)
   const updateRouteCalculationSetting = useStore(state => state.updateRouteCalculationSetting)
   const trafficFlowEnabled = useStore(store => store.trafficFlowEnabled)
   const setTrafficFlowEnabled = useStore(store => store.setTrafficFlowEnabled)
   const debugModeEnabled = useStore(store => store.debugModeEnabled)
   const setDebugModeEnabled = useStore(store => store.setDebugModeEnabled)
   const charging = useStore(store => store.charging)
   const updateCharging = useStore(store => store.updateCharging)
   const updateStore = useStore(store => store.update)
   const routePreferences = useStore(s => s.routePreference)
   const routeResult = useStore(store => store.routeResult)
   const selectedRouteId = useStore(store => store.selectedRouteId)
   const alternativeRouteSelection = useStore(store => store.alternativeRouteSelection)

   const routeToGeoJSON = (route: Route) => {
      if (route?.segments.length > 0) {
         return route.segments.flatMap(seg => toGeoJSON(seg.polyline.line).coordinates)
      } else {
         return []
      }
   }

   function isAlternativeRouteSeelcted() {
      return (
         routeResult &&
         selectedRouteId >= 0 &&
         selectedRouteId < routeResult.routes.length &&
         routeResult.routes[selectedRouteId].alternative_reroute_info !== undefined
      )
   }

   function activateRerouting() {
      if (isAlternativeRouteSeelcted()) {
         const info = routeResult?.routes[selectedRouteId].alternative_reroute_info
         const optimalRoute = routeResult?.routes.find(r => r.alternative_reroute_info === undefined)
         if (info && optimalRoute) {
            updateStore(s => {
               s.alternativeRouteSelection = {
                  alternativePathInfo: info,
                  selectedAlternativeRoute: routeToGeoJSON(routeResult?.routes[selectedRouteId]),
                  optimalRoute: routeToGeoJSON(optimalRoute),
               }
            })
         }
      }
   }

   function deactivateRerouting() {
      updateStore(s => {
         s.alternativeRouteSelection = undefined
      })
   }

   return (
      <Box>
         <FormControl fullWidth sx={{ mb: 2 }}>
            <FormLabel component={"legend"}>{t("VehicleInput_CalculationSetting")}</FormLabel>

            <FormControlLabel
               control={
                  <Switch
                     onChange={e => {
                        updateRouteCalculationSetting({ useTrafficRealtime: e.target.checked })
                     }}
                     checked={routeCalculationSetting.useTrafficRealtime}
                  />
               }
               label={t("VehicleInput_UseRTTI")}
            />
            {[RoadProperty.HIGHWAYS_MOTORWAYS, RoadProperty.TOLL_ROADS, RoadProperty.FERRY, RoadProperty.TUNNELS].map(
               roadProp => (
                  <FormControlLabel
                     control={
                        <Switch
                           onChange={e => {
                              updateStore(s => {
                                 s.routePreference = s.routePreference.filter(p => p.road_property !== roadProp)
                                 if (e.target.checked)
                                    s.routePreference.push({ road_property: roadProp, pref: PreferenceType.EXCLUDED })
                              })
                           }}
                           checked={routePreferences.some(
                              p => p.road_property === roadProp && p.pref === PreferenceType.EXCLUDED,
                           )}
                        />
                     }
                     label={t("Avoid_" + roadProp)}
                  />
               ),
            )}
            <FormControlLabel
               control={
                  <Switch
                     onChange={e => {
                        updateRouteCalculationSetting({ wantManeuvers: e.target.checked })
                     }}
                     checked={routeCalculationSetting.wantManeuvers}
                  />
               }
               label={t("VehicleInput_WantManeuvers")}
            />
            <FormControlLabel
               control={
                  <Switch
                     onChange={e => {
                        updateRouteCalculationSetting({ useAlternatives: e.target.checked })
                     }}
                     checked={routeCalculationSetting.useAlternatives}
                  />
               }
               label={t("VehicleInput_UseAlternatives")}
            />
            <FormControlLabel
               control={
                  <Switch
                     onChange={e => {
                        if (e.target.checked) {
                           activateRerouting()
                        } else {
                           deactivateRerouting()
                        }
                     }}
                     disabled={alternativeRouteSelection === undefined && !isAlternativeRouteSeelcted()}
                     checked={alternativeRouteSelection !== undefined}
                  />
               }
               label={"re-routing"}
            />
            <FormControlLabel
               control={
                  <Switch
                     onChange={e => {
                        setTrafficFlowEnabled(e.target.checked)
                     }}
                     checked={trafficFlowEnabled && routeCalculationSetting.withRouteLinks}
                     disabled={!routeCalculationSetting.withRouteLinks}
                  />
               }
               label={t("VehicleInput_TrafficFlow")}
            />

            <FormControl fullWidth>
               <InputLabel id="label-selectRoutingAlgorithm">Algorithm</InputLabel>
               <Select
                  id="selectRoutingAlgorithm"
                  value={routeCalculationSetting.routingAlgorithm}
                  label="Algorithm"
                  onChange={e => {
                     updateRouteCalculationSetting({ routingAlgorithm: e.target.value as RoutingAlgorithm })
                  }}
               >
                  <MenuItem value={RoutingAlgorithm.NDS_ASTAR}>NDS A*</MenuItem>
                  <MenuItem value={RoutingAlgorithm.CONTRACTION_HIERARCHIES}>Contraction Hierarchies</MenuItem>
                  <MenuItem value={RoutingAlgorithm.CELL_BASED}>Cell Based</MenuItem>
               </Select>
            </FormControl>

            <FormControlLabel
               control={
                  <Switch
                     onChange={e => {
                        setDebugModeEnabled(e.target.checked)
                     }}
                     checked={debugModeEnabled}
                  />
               }
               label={t("VehicleInput_DevMode")}
            />
            {debugModeEnabled && (
               <FormControlLabel
                  control={
                     <Switch
                        onChange={e => {
                           updateRouteCalculationSetting({ withRouteLinks: e.target.checked })
                        }}
                        checked={routeCalculationSetting.withRouteLinks}
                     />
                  }
                  label={t("VehicleInput_WithRouteLinks")}
               />
            )}
            {debugModeEnabled && (
               <FormControlLabel
                  control={
                     <Switch
                        onChange={e => {
                           updateRouteCalculationSetting({ curvyRoute: e.target.checked })
                        }}
                        checked={routeCalculationSetting.curvyRoute}
                     />
                  }
                  label={t("VehicleInput_CurvyRoute")}
               />
            )}
         </FormControl>

         <Box sx={{ display: "EV" === vehicle.type ? "inline" : "none" }}>
            <BatteryConfiguration />
         </Box>
         <SupplierSelectorComponent
            activeSuppliers={
               charging.provider_preference?.map(sup => chargerSupplierNameToSupplier(sup.provider)) ?? []
            }
            disabled={false}
            suppliers={getSuppliers()}
            updateSuppliers={sup =>
               updateCharging({
                  provider_preference: sup.map(sup => {
                     return {
                        pref: "preferred",
                        provider: sup.name ?? "",
                     }
                  }),
               })
            }
         />
         <PreferredChangers />
      </Box>
   )
}
